<template>
  <div class="bg bg9">
    <div class="class--con">
      <class-but i-s-throw-out @backOfff="backOfff" @forward="forward"/>
<!--      <img class="title&#45;&#45;img" :src="menu1" alt="">-->
      <div class="class--container">
        <div style="text-align: center">
          <img  :src="menu2" alt="">
        </div>
        <div style="display: flex;justify-content: space-evenly">
          <div class="class--container__StarList" v-for="(i,k) in currentPageData" :key="k">
            <img :src="i.sex === 1 ? menu3 : menu4" alt="">
            <div style="display: flex;align-items: center;justify-content: space-between">
              <img :src="menu5" alt="">
              <span style=" color: #2B3654;font-size: 47px">{{ i.studentName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import menu1 from "@/assets/image/conference/btn_shouh.png"
import menu2 from "@/assets/image/conference/bt_title1.png"
import menu3 from "@/assets/image/conference/ic_boy (1).png" //男
import menu4 from "@/assets/image/conference/ic_girl (1).png" //女
import menu5 from "@/assets/image/conference/ic_medal (1).png"
import service from "@/utils/axios";

export default {
  name: "elevenPage",
  components: {ClassBut},
  data() {
    return {
      // menu1: menu1,
      menu2: menu2,
      menu3: menu3,
      menu4: menu4,
      menu5: menu5,
      courseAllPercent: [],//全部数据
      totalPage: 1, // 统共页数，默认为1
      currentPage: 1, //当前页数 ，默认为1
      pageSize: 4, // 每页显示数量
      currentPageData: [], //当前页显示内容
    }
  },
  created() {
    this.getApicourseAllPercent()
  },


  methods: {
    forward() {
      //下一页 PageThirteenth
      if (this.currentPage === this.totalPage) {
        if ( this.$route.query.subjectName !== '儿童画' ) {
          this.$router.push({
            name: 'PageThirteenth',
            query: this.$route.query,
          })
        }else {
          this.$router.push({
            name: 'PageTwelfth',
            query: this.$route.query,
          })
        }
        return
      }
      this.currentPage++;
      this.setCurrentPageData();
    },

    backOfff() {
      //上一页
      if (this.currentPage === 1) {
        this.$router.back(-1)
        return
      }
      this.currentPage--;
      this.setCurrentPageData();
    },

    async getApicourseAllPercent() {
      let res = await service.post(`/dwart/an_teaching/hbk/v1/search/courseAllPercent`, {
        classId: this.$route.query.classId
      })
      this.courseAllPercent = res
      if ( this.courseAllPercent.length === 0 ) {
        if ( this.$route.query.subjectName !== '儿童画' ) {
          this.$router.replace({
            name: 'PageThirteenth',
            query: this.$route.query,
          })
        }else {
          this.$router.replace({
            name: 'PageTwelfth',
            query: this.$route.query,
          })
        }
      }
      await this.setCurrentPageData()
    },

    setCurrentPageData() {
      this.totalPage = Math.ceil(this.courseAllPercent.length / this.pageSize);
      this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
      let begin = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      this.currentPageData = this.courseAllPercent.slice(begin, end);
    },

  }
}
</script>

<style lang="less" scoped>
@import "reportClass.less";

.class--container {
  padding: 90px 10px 90px 90px;
  box-sizing: border-box;
}

.class--container__StarList {
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    width: 348px;
    height: 348px;
  }
}
</style>
